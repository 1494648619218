import React from "react";
import styled from "styled-components";

import SocialMedia from "../utility/SocialMedia";
import Newsletter from "../forms/NewsletterForm";

const PortraitStyles = styled.div`
  border: 1px solid var(--grey);
  border-radius: 5px;
  padding: 10px;
  color: var(--black) !important;

  img {
    width: 100%;
    max-width: 100%;
  }

  ul {
    display: flex;
    list-style: none;
    padding-left: 0;

    svg {
      color: var(--black);
    }
  }
`;

export default function AboutCardPortrait({ signup }) {
  return (
    <PortraitStyles>
      <Newsletter signup={signup} />

      <SocialMedia />
    </PortraitStyles>
  );
}
