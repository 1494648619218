import React from "react";
import styled from "styled-components";
import { PortableText } from "@portabletext/react";

import sanityConnectInfo from "../../../sanityConnectInfo.json";
import components from "../../utils/componentsForPortableText";

const SupportStyles = styled.div`
  padding: 5%;
  margin: 1rem;
  border: 2px dashed var(--color4);

  .caption {
    font-size: 2rem !important;
    color: var(--black) !important;
    text-align: center;
  }

  .support {
    h2 {
      text-transform: uppercase;
      a {
        color: var(--color3);
      }

      @media (max-width: 800px) {
        text-align: center;
      }
    }

    img {
      width: 150px;
    }

    .payment-gateways {
      display: grid;
      grid-template-columns: repeat(auto-fit, 200px);
      gap: 2rem;

      @media (max-width: 800px) {
        grid-template-columns: 1fr;
      }
    }
  }
`;

const SupportBand = ({ support }) => {
  return (
    <SupportStyles>
      <div className="support">
        <h2>{support.title}</h2>

        <div className="payment-gateways">
          <PortableText
            value={support.textEditor._rawRichText}
            projectId={sanityConnectInfo.projectId}
            dataset={sanityConnectInfo.dataset}
            components={components}
          />
        </div>
      </div>
    </SupportStyles>
  );
};

export default SupportBand;
