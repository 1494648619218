import React from "react";

import Linka from "./Linka";
import TipJar from "../../images/tip-jar-banner.gif";

export default function SupportButton() {
  return (
    <Linka
      to="https://www.paypal.com/donate/?hosted_button_id=AEY8K2M36ZG5N"
      rel="noreferrer noopener"
      target="_blank"
    >
      <img
        src={TipJar}
        alt={`Tip Jar. Sponsor a blog post. The world has become a hostile place for families. Support our mission to inspire and defend family life.`}
      />
    </Linka>
  );
}
