import React, { useState, useRef, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import axios from "axios";

import { PortableText } from "@portabletext/react";
import components from "../../utils/componentsForPortableText";

import sanityConnectInfo from "../../../sanityConnectInfo.json";

const NewsFormStyles = styled.div`
  max-width: 100%;

  display: grid;
  align-content: center;

  .notify {
    &.notify-visible {
      transform: translateX(0);
      transition-timing-function: ease-out;
      transition: 0.8s;
    }

    &.notify-invisible {
      transform: translateX(-130%);
    }
  }

  .newsletter-title {
    p {
      margin: 0;
      padding: 1rem;
      font-size: 1rem;
      color: var(--white);
    }

    h3 {
      font-size: 1.5rem;
      margin-left: 1rem;
    }
  }

  form {
    display: grid;
    grid-template-columns: 2fr, 1fr;
    gap: 1rem;

    @media (max-width: 700px) {
      grid-template-columns: 1fr;

      button {
        width: 100%;
        font-size: 1rem;
      }
    }

    button {
      background: var(--color4);
      color: var(--color3);
      border: none;
    }
  }

  input {
    border-radius: 3px;
    width: auto;
  }

  .password {
    display: none;
  }
`;

const NewsletterForm = ({ signup }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [travelBlogs, setTravelBlogs] = useState({});
  const [familyLifeBlogs, setFamilyLifeBlogs] = useState({});

  const [featuredProducts, setFeaturedProducts] = useState([]);
  const [socialChannels, setSocialChannels] = useState([]);

  const [notify, setNotify] = useState({});
  const notifyTimeout = useRef();

  const data = useStaticQuery(graphql`
    query {
      signup: sanitySection(title: { eq: "Sign Up Tagline" }) {
        textEditor {
          _rawRichText(resolveReferences: { maxDepth: 10 })
        }
      }

      bigcommerce: allBigCommerce(
        filter: { is_featured: { eq: true } }
        limit: 4
      ) {
        edges {
          node {
            primary_image {
              url_standard
              description
            }
            custom_url {
              url
            }
            name
          }
        }
      }

      social: allSanityChannel {
        nodes {
          title
          image {
            image {
              asset {
                url
              }
            }
          }
          url
        }
      }

      travelBlogs: allSanityBlog(
        filter: {
          pillar: { eq: true }
          blogCategory: { slug: { current: { eq: "travel" } } }
        }
        limit: 2
      ) {
        nodes {
          image {
            image {
              asset {
                url
              }
            }
            alt
          }
          title
          slug {
            current
          }
          description
        }
      }

      familyLifeBlogs: allSanityBlog(
        filter: {
          pillar: { eq: true }
          blogCategory: { slug: { current: { eq: "family-life" } } }
        }
        limit: 2
      ) {
        nodes {
          image {
            image {
              asset {
                url
              }
            }
            alt
          }
          title
          slug {
            current
          }
          description
        }
      }
    }
  `);

  const beginNotifyCountdown = () => {
    notifyTimeout.current = setTimeout(() => {
      setNotify({});
    }, 5000);
  };

  useEffect(() => {
    getFeaturedProducts({ data });
    getSocialChannels({ data });

    getTravelBlogs({ data });
    getFamilyLifeBlogs({ data });

    beginNotifyCountdown();

    return () => {
      clearTimeout(notifyTimeout.current);
    };
  }, [data]);

  const getFeaturedProducts = ({ data }) => {
    const products = [];

    data.bigcommerce.edges.forEach((product) => {
      const featured = {
        image: {
          url: product.node.primary_image.url_standard,
          alt: product.node.name,
        },
        description: product.node.primary_image.description,
        linkUrl: "https://shop.familyilove.com" + product.node.custom_url.url,
        title: product.node.name,
      };

      products.push(featured);
    });

    setFeaturedProducts(products);
  };

  const getSocialChannels = ({ data }) => {
    const social = [];

    data.social.nodes.forEach((channel) => {
      const socialChannels = {
        socialTitle: channel.title,
        socialUrl: channel.url,
        socialImage: channel.image.image.asset.url,
      };

      social.push(socialChannels);
    });

    setSocialChannels(social);
  };

  const getTravelBlogs = ({ data }) => {
    const travel = {};

    data.travelBlogs.nodes.forEach((post, i) => {
      const output = {
        title: post.title,
        description: post.description,
        image: {
          url: post.image.image.asset.url,
          alt: post.image.alt,
        },
        linkUrl: "https://familyilove.com/" + post.slug.current,
      };

      travel[`blog${i + 1}`] = output;
    });

    setTravelBlogs(travel);
  };

  const getFamilyLifeBlogs = ({ data }) => {
    const familyLife = {};

    data.familyLifeBlogs.nodes.forEach((post, i) => {
      const output = {
        title: post.title,
        description: post.description,
        image: {
          url: post.image.image.asset.url,
          alt: post.image.alt,
        },
        linkUrl: "https://familyilove.com/" + post.slug.current,
      };

      familyLife[`blog${i + 1}`] = output;
    });

    setFamilyLifeBlogs(familyLife);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const body = {
      email,
      password,

      travelBlogs,
      familyLifeBlogs,

      featuredProducts,
      socialChannels,
    };

    axios
      .post(
        `${process.env.GATSBY_SERVERLESS_BASE}/signupNewsletter`,
        JSON.stringify(body),
      )
      .then((res) => {
        setEmail("");
        setPassword("");

        setNotify({ msg: res.data, color: "#028703" });
        beginNotifyCountdown();
      })
      .catch((err) => {
        console.log(err.response);

        setNotify({
          msg: err.response?.data
            ? err.response.data
            : "Something went wrong! Please try to subscribe again!",
          color: "#f01f1f",
        });
        beginNotifyCountdown();
      });
  };

  return (
    <NewsFormStyles>
      <div
        className={`notify ${
          notify.msg ? "notify-visible" : "notify-invisible"
        }`}
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          backgroundColor: notify.color,
          color: "white",
          padding: "0 20px",
          zIndex: 9999,
        }}
      >
        <p>{notify.msg}</p>
      </div>

      <span className="newsletter-title">
        {signup ? (
          <h3>{signup}</h3>
        ) : (
          <PortableText
            value={data.signup.textEditor._rawRichText}
            projectId={sanityConnectInfo.projectId}
            dataset={sanityConnectInfo.dataset}
            components={components}
          />
        )}
      </span>
      <form onSubmit={handleSubmit}>
        <input
          type="email"
          name="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        <div className="password">
          <input
            name="password"
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>

        <button type="submit">Sign Up</button>
      </form>
    </NewsFormStyles>
  );
};

export default NewsletterForm;
