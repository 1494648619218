import React from "react";
import styled from "styled-components";
import { graphql } from "gatsby";

import SearchEngineOptimization from "../components/utility/SearchEngineOptimization";
import Articles from "../components/blog/ArticlesSection";
import AboutCard from "../components/cards/AboutCardPortrait";
import AboutCardBottom from "../components/cards/AboutCardLandscape";
import SupportBand from "../components/general/SupportBand";
import TipJar from "../components/utility/TipJar";

const TagStyles = styled.div`
  .tag-page {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 2rem;
    padding-left: 5%;
    padding-right: 5%;

    @media (max-width: 950px) {
      margin-top: 80px;
    }

    @media (max-width: 900px) {
      grid-template-columns: 1fr;
      margin-top: 20px;
    }
  }

  .sidebar {
    .sidebar-about {
      margin-bottom: 1rem;
    }

    .sidebar-tip-jar img {
      width: 600px;
      max-width: 100%;
    }
  }

  .bottom-about {
    display: none;
  }

  h1 {
    text-align: center;
  }

  @media (max-width: 900px) {
    display: block;

    .sidebar {
      display: none;
    }

    .bottom-about {
      display: block;
      margin-bottom: 1rem;
    }
  }
`;

export function Head({ data: { tag }, location, ...props }) {
  return (
    <SearchEngineOptimization
      {...props}
      meta={{
        title: tag.title.toUpperCase(),
        ogType: "article",
        createdAt: tag._createdAt,
        updatedAt: tag._updatedAt,
      }}
      location={location}
    />
  );
}

export default function tagPage({
  data: {
    blogs: { nodes: blogs },
    about,
    tag,
    paymentGateways,
  },
  location,
}) {
  return (
    <>
      <TagStyles>
        <div className="tag-page">
          <div className="content">
            <h1>{tag.title}</h1>

            <div>
              <Articles blogs={blogs} isInfinite={true} />
            </div>
          </div>

          <div className="sidebar">
            <div className="sidebar-about">
              <AboutCard about={about} />
            </div>

            <div className="sidebar-tip-jar">
              <TipJar />
            </div>
          </div>
        </div>
        <div className="bottom-about">
          <AboutCardBottom about={about} />
        </div>
        <div className="bottom-support-band">
          <SupportBand support={paymentGateways} />
        </div>
      </TagStyles>
    </>
  );
}

export const query = graphql`
  query ($slug: String!) {
    tag: sanityTag(slug: { current: { eq: $slug } }) {
      _createdAt
      _updatedAt
      title
      slug {
        current
      }
    }

    blogs: allSanityBlog(
      filter: {
        publicityStatus: { eq: "PUBLISHED" }
        tags: { elemMatch: { slug: { current: { eq: $slug } } } }
      }
    ) {
      nodes {
        title
        description
        slug {
          current
        }

        image {
          image {
            asset {
              gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
            }
          }
        }

        blogCategory {
          title
          slug {
            current
          }

          image {
            image {
              asset {
                gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
              }
            }
          }
        }
        tags {
          slug {
            current
          }
          title
        }
        textEditor {
          _key
          _rawRichText
          _type
        }
      }
    }

    about: sanitySection(title: { eq: "About Family I Love" }) {
      title
      textEditor {
        _key
        _type
        _rawRichText(resolveReferences: { maxDepth: 10 })
      }
    }

    paymentGateways: sanitySection(title: { eq: "Support The Band" }) {
      textEditor {
        _rawRichText(resolveReferences: { maxDepth: 10 })
      }
      title
    }
  }
`;
